/* Carousel Custom Style */
.testi-slider .slick-slide{
    transform: scale(0.95);
}
.testi-slider .testimonial .testi-slider .item .testi-box{
    margin: 0;
}
.testi-slider .slide_img_wrapper{
    height: 347px;
    width: 100%;
}
.testi-slider .slide_img_wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.testi-slider .slick-dots li button:before{
    font-size: 14px !important;
    color: rgb(0 118 163) !important;
}
.testi-slider .slick-dots li.slick-active button:before{
 color: #0076a3 !important;
}



.app-slider .slick-slide{
    user-select: none;
}
.app-slider .slick-slide img{
    display: block;
    width: 83%;
    margin: 0 auto;
}
.app-slider .slick-dots li button:before{
    font-size: 10px !important;
    color: rgb(0 118 163) !important;
}
.app-slider .slick-dots li.slick-active button:before{
 color: #0076a3 !important;
}
.app-slider .slick-dots{
    bottom: -40px;
}