.survey {
  margin-top: 120px;
}
.survey .category {
  font-size: 20px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 10px;
}
.survey_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border: 1px solid #f8c72f;
  padding: 10px;
  border-radius: 10px;
  height: 100%;
  text-decoration: none;
  outline: none;
}
.survey_box .status {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #dc3545;
  font-size: 12px;
  padding: 4px 15px;
  color: #fff;
  border-radius: 20px;
  text-transform: capitalize;
}
.survey_box .status.completed {
  background-color: #f44336;
  color: #fff;
}

.survey_box .status.active {
  background-color: darkgreen;
  color: #fff;
}

.survey_box .image_wrapper {
  height: 100px;
  width: 100px;
  max-height: max-content;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 15px;
}
.survey_box .image_wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.content_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 0;
}
.content_wrapper .name {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
  color: #000;
  text-transform: capitalize;
}
.content_wrapper .desc {
  font-weight: 400;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
  color: #878787;
  text-transform: capitalize;
}

.content_wrapper .name_details {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 2px;
  color: #000;
  text-transform: capitalize;
}
.content_wrapper .desc_details {
  font-weight: 400;
  font-size: 14px;
  color: #878787;
  text-transform: capitalize;
}

.content_wrapper .attempt {
  font-weight: 500;
  font-size: 14px;
  color: #f44336;
  margin-top: 8px;
  text-transform: capitalize;
}
.content_wrapper .attempt img {
  width: 14px;
  margin-right: 5px;
}

.survey_box.details {
  align-items: flex-start;
  height: auto;
}
.survey_box.details .image_wrapper {
  width: 70px;
  height: 70px;
  min-width: 70px;
  margin: 5px 10px 5px 5px;
}
.survey_box.details .content_wrapper {
  justify-content: center;
  height: auto;
  padding-top: 0;
}

.MuiPaper-root {
  box-shadow: none !important;
  margin: 0 0 10px 0;
  border: 1px solid #00000059;
  border-radius: 10px !important;
}
.MuiPaper-root::before {
  display: none;
}
.acordian_drop {
  width: 13px;
}
.acc_icon {
  width: 25px;
  margin-right: 10px;
}
.acc_icon img {
  width: 100%;
}
.MuiAccordionSummary-content {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.detail_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #8f8f8f59;
  border-radius: 10px;
  overflow: hidden;
}
.detail_box:not(:last-child) {
  margin-bottom: 15px;
}
.detail_box .details_img {
  width: 80px;
  height: 80px;
  margin-right: 15px;
}
.detail_box .details_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.detail_box .details_details .title {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}
.detail_box .details_details .desc {
  font-size: 14px;
  font-weight: 400;
}
.detail_box .details_details .link {
  font-size: 14px;
  font-weight: 400;
}
.show_result {
  padding: 20px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.show_result button {
  padding: 10px 40px;
  border: 0px;
  background-color: #f8c72f;
  border-radius: 50px;
  font-size: 20px;
  color: #fff;
}

/* New css 22 June 2024 start */
/* "/surveys" surveys start */
.sticky_filter_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky !important;
  top: 76px;
  z-index: 2;
  background: #fff;
  padding-top: 24px;
  padding-bottom: 24px;
}
.surveys_heading {
  font-size: 18px;
  font-weight: 700;
  color: #1d242c;
}
.surveys_left_column {
  border: 1px solid #49657b33;
  border-radius: 12px;
  overflow: hidden;
  position: sticky;
  top: 150px;
}
.surveys_left_column.nav-pills .nav-link,
.surveys_left_column.nav-pills .show > .nav-link {
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 20px;
  border-radius: 0;
}
.surveys_left_column.nav-pills .nav-link span {
  font-size: 12px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  text-align: center;
  color: #fff;
  background-color: #0b6eca;
  padding: 0 10px;
  border-radius: 9px;
}
.surveys_left_column.nav-pills .nav-item + .nav-item {
  border-top: 1px solid #49657b33;
}
.surveys_left_column.nav-pills .nav-link.active,
.surveys_left_column.nav-pills .show > .nav-link {
  border-radius: 0;
  background-color: #0b6eca;
}
.surveys_left_column.nav-pills .nav-link.active span {
  font-size: 12px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  text-align: center;
  color: #191a1d;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 9px;
}
.survey_dropdown_container {
  position: relative;
  padding: 8px 6px 8px 10px;
  cursor: pointer;
}
.survey_dropdown_btn {
  font-size: 16px;
  font-weight: 500;
  color: #777;
}
.survey_dropdown_btn span {
  color: #191a1d;
  font-weight: 600;
}
.survey_dropdown_btn span span {
  font-size: 12px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  text-align: center;
  color: #fff;
  background-color: #0b6eca;
  padding: 2px 6px;
  border-radius: 10px;
}
.survey_dropdown {
  position: absolute;
  width: max-content;
  top: 140%;
  right: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 5px 10px #1d242c40;
  border-radius: 10px;
}
.survey_dropdown ul {
  list-style: none;
  padding: 0 0 0;
  margin: 10px 0 14px;
}
.survey_dropdown ul li {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  gap: 10px;
  font-size: 16px;
  color: #1d242c;
  font-weight: 600;
  padding: 8px 24px 8px 16px;
}
.survey_dropdown ul li input {
  border-color: #49657b33;
}
.survey_dropdown ul li input:checked {
  background-color: #0b6eca;
  border-color: #0b6eca;
}
.surveys_right_column {
}
.survey_card {
  position: relative;
  height: 300px;
  border-radius: 16px;
}
.survey_card:hover {
  cursor: pointer;
  box-shadow: 0 3px 10px #1d242c70;
}
.survey_card > img {
  border-radius: 16px;
}
.survey_card_status {
  position: absolute;
  inset: 15px 15px auto;
  color: #fff;
  background-color: red;
  width: fit-content;
  padding: 2px 12px 2px 22px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: 500;
}
.survey_card_status::before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 50%;
}
.survey_card_status.survey_card_active {
  background-color: #17ad6d;
}
.survey_card_status.survey_card_completed {
  background-color: #0b6eca;
}
.survey_card_content {
  position: absolute;
  inset: auto 15px 15px;
  color: #fff;
}
.survey_card_title {
  font-family: "Fraunces", serif;
  font-size: 20px;
  font-weight: 600;
}
.survey_card_extra {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
}
.survey_card_extra > span {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 600;
}
/* "/surveys" surveys end */
/* "/survey-details/abcde12345" surveys start */
.page_breadcrumb {
  display: flex;
  align-items: center;
}
.page_breadcrumb > img:hover {
  cursor: pointer;
  opacity: 0.7;
}
.page_breadcrumb ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0 0 0 12px;
  padding: 0;
}
.page_breadcrumb ul li {
  padding: 2px 4px;
  font-size: 14px;
  font-weight: 500;
  color: #1d242c;
  cursor: pointer;
}
.page_breadcrumb ul li:hover {
  color: #999;
}
.page_breadcrumb ul li + li {
  margin-left: 32px;
  position: relative;
  color: #49657b;
}
.page_breadcrumb ul li + li::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  border: 1px solid #49657b;
  border-left: none;
  border-top: none;
  width: 7px;
  height: 7px;
  pointer-events: none;
}
.survey_thumbnail_card {
  border-radius: 14px;
  overflow: hidden;
}
.survey_thumbnail_card > img {
  width: 100%;
  min-height: 265px;
  max-height: 265px;
  object-fit: cover;
  object-position: center;
}
.survey_thumbnail_content {
  background-color: #0b6eca20;
  padding: 15px 0;
  display: flex;
  align-items: center;
}
.survey_thumbnail_left_content,
.survey_thumbnail_right_content {
  padding: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.survey_thumbnail_left_content {
}
.survey_thumbnail_right_content {
  border-left: 1px solid #49657b33;
}
.survey_thumbnail_right_content > img {
  border-radius: 50%;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}
.survey_thumbnail_left_content p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #191a1d;
}
.survey_thumbnail_left_content p span {
  font-size: 16px;
  font-weight: 700;
  color: #0b6eca;
}
.survey_thumbnail_right_content p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #49657b;
}
.survey_thumbnail_right_content p span {
  font-weight: 700;
  color: #191a1d;
}
.survey_prize_card {
  position: relative;
  max-height: 250px;
  overflow: hidden;
  border-radius: 14px;
}
.survey_prize_card::after {
  position: absolute;
  content: "";
  inset: 0;
  background-color: #00000066;
}
.survey_prize_card > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.survey_prize_content {
  background-color: #fff;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding: 14px 8px;
}
.survey_prize_content > p {
  position: absolute;
  color: #fff;
  bottom: 100%;
  font-weight: 600;
}
.survey_prize_content > h6 {
  font-size: 18px;
  color: #191a1d;
}
.survey_prize_content > span {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #119ebd;
}
.survey_winner_heading {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: "Fraunces", serif;
  font-size: 18px;
  font-weight: 600;
  color: #191a1d;
}
.survey_winner_content {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #0b6eca1a;
  border: 1px solid #49657b1a;
  border-radius: 10px;
  padding: 12px 14px;
}
.survey_winner_content > img {
  display: flex;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 50%;
}
.survey_winner_content > div {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.survey_winner_name {
  color: #191a1d;
  font-size: 16px;
  font-weight: 600;
}
.survey_winner_place {
  color: #191a1d;
  font-size: 14px;
  font-weight: 400;
}
.survey_title {
  font-family: "Fraunces", serif;
  font-size: 22px;
  font-weight: 700;
  color: #191a1d;
}
.survey_description {
  font-size: 14px;
  font-weight: 400;
  color: #49657b;
  line-height: 20px;
  -webkit-box-orient: "vertical";
  -webkit-line-clamp: 3;
  overflow: "hidden";
  text-overflow: "ellipsis";
}
.survey_description > span {
  color: #0b6eca;
}
.survey_extra {
  display: flex;
  align-items: center;
  gap: 20px;
}
.survey_extra > div {
  background-color: #99a9b526;
  border-radius: 25px;
  padding: 6px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}
.survey_extra > div > span {
  font-weight: 700;
}
.survey_show_result_btn {
  background-color: #0b6eca;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 16px;
  border-radius: 25px;
}
.survey_show_result_btn:hover {
  cursor: pointer;
  background-color: #0d68bd;
}
.survey_show_result_btn.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.7;
}
hr.row_divider {
  margin: 20px 0;
  border-top: 1px solid #49657b99;
}
.survey_section_heading {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #191a1d;
  font-size: 18px;
  font-weight: 600;
  font-family: "Fraunces", serif;
}
.createdby_left_box {
  background-color: #f6f7f8;
  border-radius: 10px;
  padding: 16px;
  height: 100%;
}
.createdby_left_box span {
  font-family: "Fraunces", serif;
  font-size: 14px;
}
.createdby_left_box h5 {
  color: #191a1d;
  font-size: 20px;
  font-weight: 600;
  font-family: "Fraunces", serif;
  margin: 10px 0;
}
.createdby_left_box p {
  font-size: 14px;
  line-height: 20px;
  color: #49657b;
}
.createdby_right_box {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #edf0f2;
  border-radius: 10px;
}
.createdby_right_box li {
  font-family: "Fraunces", serif;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  font-size: 14px;
}
.createdby_right_box li + li {
  border-top: 1px solid #edf0f2;
}
.createdby_right_box li span {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
}
.sponsor_card {
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #49657b33;
  overflow: hidden;
}
.sponsor_card > img {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 6px;
  object-fit: cover;
}
.sponsor_card > h6 {
  font-size: 15px;
  font-weight: 700;
  color: #191a1d;
  margin: 10px 0 5px;
  line-height: normal;
}
.sponsor_card > p {
  font-size: 12px;
  font-weight: 400;
  color: #49657b;
  line-height: normal;
  margin: 0;
}
.participant_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
}
.participant_card > img {
  padding: 16px;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
}
.participant_card span {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #191A1D;
  margin-top: -8px;
}
.participant_card p {
  text-align: center;
  color: #49657B;
  font-size: 14px;
  margin: 0;
}
.survey_result_heading {
  font-family: "Fraunces", serif;
  font-weight: 700;
  font-size: 22px;
  margin: 50px 0 10px;
}
.filter_btn {
  margin: 50px 0 10px;
  background-color: #0b6eca;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 32px;
  border-radius: 25px;
  border: none;
}
.filter_btn:hover {
  cursor: pointer;
  background-color: #0d68bd;
}
.gender_filter,
.age_filter {
  background-color: #F6F7F8;
  border-radius: 10px;
  padding: 10px;
}
.gender_filter_title,
.age_filter_title {
  font-family: "Fraunces", serif;
  font-weight: 600;
  color: #191A1D;
  display: block;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gender_filter_btn {
  position: relative;
}
.gender_filter_btn > div {
  border-radius: 8px;
  border: 1px solid #EDF0F2;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
}
.gender_filter_btn input {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.gender_filter_btn > div > div {
  width: 15px;
  height: 15px;
  border: 1px solid #6A758B80;
  border-radius: 50%;
  display: flex;
}
.gender_filter_btn div img {
  width: 100%;
  height: 100%;
  display: none;
}
.gender_filter_btn span {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #49657B;
  font-family: "Manrope", sans-serif;
}
.gender_filter_btn input:checked + div {
  border-color: #0B6ECA;
}
.gender_filter_btn input:checked + div > div {
  border: none;
}
.gender_filter_btn input:checked + div img {
  display: flex;
}
.multi_range_slider {
  position: relative;
  margin: 12px 0 30px;
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 5px;
  height: 9px;
}

.slider__track {
  background-color: #7E879F33;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #0B6ECA;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #201838;
  font-size: 12px;
  font-weight: 500;
  margin-top: 25px;
}

.slider__left-value {
  left: 0;
}

.slider__right-value {
  right: -0;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #0B6ECA;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  border: 2px solid #fff;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 8px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #7E879F33;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 11px;
  width: 11px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
.filter_btn_group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 32px;
}
.apply_filter_btn {
  width: 100%;
  background-color: #0B6ECA;
  color: #fff;
  border-radius: 35px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  padding: 16px 12px;
}
.apply_filter_btn:hover {
  background-color: #0d68bd;
}
.clear_filter_btn {
  font-size: 14px;
  color: #49657B;
  border: none;
  background: transparent;
}
.clear_filter_btn:hover {
  color: #648aa6;
}
.survey_questions_card {
  background-color: #49657B0D;
  border-radius: 16px;
  padding: 20px 16px;
}
.survey_question_title {
  font-family: "Fraunces", serif;
  font-weight: 600;
  margin: 0;
}
.survey_question_mcq {
  list-style: none;
  padding: 0;
  margin: 0 32px 0 0;
  border: 1px solid #EDF0F2;
  background-color: #fff;
  border-radius: 10px;
}
.survey_question_mcq li {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
}
.survey_question_mcq li + li {
  border-top: 1px solid #EDF0F2;
}
.survey_question_mcq li span {
  font-size: 14px;
  color: #191A1D;
  transition: all 0.1s ease;
}
.survey_question_mcq li input {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  opacity: 0;
  cursor: pointer;
}
.survey_question_mcq li img {
  opacity: 0;
  transition: all 0.2s ease;
}
.survey_question_mcq li input:checked + span {
  font-weight: 600;
}
.survey_question_mcq li input:checked + span + img {
  opacity: 1;
}
div.recharts-legend-wrapper {
  width: 100% !important;
  bottom: 24px !important;
}
li.recharts-legend-item.legend-item-0 .recharts-surface {
  display: none !important;
}
.dropdown_filter {
  border: 1px solid #EDF0F2;
  border-radius: 10px;
}
.dropdown_filter_individual {
  position: relative;
}
.dropdown_filter_individual + .dropdown_filter_individual{
  border-top: 1px solid #EDF0F2;
}
.dropdown_heading {
  padding: 20px 36px 20px 12px;
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  font-family: "Fraunces", serif;
  color: #191A1D;
  font-size: 14px;
}
.dropdown_heading span{
  font-weight: 700;
  font-family: "Manrope", sans-serif;
}
.dropdown_heading::after {
  content: '';
  position: absolute;
  border: 1px solid #49657B;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%) rotate(135deg);
  right: 16px;
  height: 9px;
  width: 9px;
  transition: all 0.2s ease;
  /* border-top: none;
  border-left: none; */
  border-bottom: none;
  border-right: none;
}
.dropdown_heading.active::after {
  transform: translateY(-50%) rotate(225deg);
  /* border-top: initial;
  border-left: initial; */
}
.dropdown_content {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 5px #ccc;
  border-radius: 10px;
  /* display: none; */
  z-index: 2;
  transition: all 0.2s ease;
  transform: scaleY(0);
  transform-origin: top;
  padding: 8px 8px;
}
.dropdown_content.active {
  transform: scaleY(1);
}
.dropdown_content input {
  width: 100%;
  border: 1px solid #6A758B40;
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 32px;
  color: #191A1D;
  font-weight: 500;
}
.dropdown_content input::placeholder {
  color: #191A1D70;
  font-weight: 400;
}
.dropdown_content input:focus {
  border: 1px solid #6A758B80;
}
.dropdown_content input:focus-visible {
  border: 1px solid #6A758B80;
  outline: none;
}
.dropdown_select {
  list-style: none;
  padding: 0;
  margin: 12px 0 16px;
  overflow-y: scroll;
  max-height: 225px;
}
.dropdown_select li {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  cursor: pointer;
  font-size: 14px;
  color: #191a1dcc;
}
.dropdown_select li + li {
  border-top: 1px solid #1d242c1a;
}
.dropdown_select li img {
  min-width: 16px;
  min-height: 16px;
  max-height: 16px;
  max-height: 16px;
  opacity: 0;
  transition: all 0.2s ease;
}
.dropdown_select li:hover,
.dropdown_select li.selected {
  font-weight: 600;
}
.dropdown_select li.selected img {
  opacity: 1;
}
/* "/survey-details/abcde12345" surveys end */

@media screen and (max-width: 991px) {
  .sticky_filter_section {
    top: 68px;
    background: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .surveys_left_column {
    flex-direction: row !important;
    flex-wrap: nowrap !important;
  }
  .surveys_left_column .nav-item {
    width: 100%;
  }
  .surveys_left_column.nav-pills .nav-item + .nav-item {
    border-left: 1px solid #49657b33;
    border-top: none;
  }
  .survey_extra_info {
    flex-direction: column;
  }
  .survey_question_mcq {
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  .survey_extra_info {
    flex-direction: row;
  }
}
@media (min-width: 576px) and (max-width: 991px) {
  .surveys_left_column_wrapper {
    position: sticky !important;
    top: 140px;
    z-index: 1;
    padding: 8px 12px 24px;
    background: #fff;
  }
}
@media screen and (max-width: 575px) {
  .survey_extra_info {
    flex-direction: column;
  }
}

/* New css 22 June 2024 end */
