/* default.css

/* COLOR */

.post-meta a:hover,
.blog-post-title:hover,
.text-primary,
.btn-outline-primary,
.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item.active,
.dropup .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:focus,
.dropup .dropdown-menu .dropdown-item.active,
.dropright .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:focus,
.dropright .dropdown-menu .dropdown-item.active,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:focus,
.dropleft .dropdown-menu .dropdown-item.active,
.link-post-content i {
    color: #D91159 !important;
}

/* BACKGROUND */
.bg-primary,
.btn-primary,
.service-box:hover .icon,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.tags a:hover,
.pagination>li>a:hover,
.pagination>li>a:focus,
.pagination>li>span:hover,
.pagination>li>span:focus,

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover,
.footer-social li a:hover i {
    background-color: #0A4259 !important;
}

.footer-social li a:hover i {
    background-color: #D91159 !important;
}
/* BORDER-COLOR */

.btn-primary,
.btn-outline-primary,
.pagination>li>a:hover,
.pagination>li>a:focus,
.pagination>li>span:hover,
.pagination>li>span:focus,
.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover,
.left-sidebar-title h4,
.custom-form .form-control:focus,
.popular-border,
.blockquote,
.link-post-content,
.border-primary {
    border-color: #0076a3 !important;
}


.navbar-custom .navbar-nav li.active a,
.navbar-custom .navbar-nav li a:hover,
.navbar-custom .navbar-nav li a:focus,
.navbar-custom .navbar-nav li a:active {
    color: #ffffff !important;
}


@media (min-width: 200px) and (max-width: 991px) {

    .navbar-custom .navbar-nav li.active a,
    .navbar-custom .navbar-nav li a:hover,
    .navbar-custom .navbar-nav li a:active {
        color: #0076a3 !important;
    }
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary .open>.dropdown-toggle.btn-primary {
    box-shadow: 0px 8px 20px -6px rgba(1, 123, 145);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary .open>.dropdown-toggle.btn-primary {
    box-shadow: 0px 8px 20px -6px rgba(1, 123, 145);
    /* transform: translateY(-6px); */
    color: #fff !important;
}

.nav-sticky.navbar-custom .navbar-nav li.active a,
.nav-sticky.navbar-custom .navbar-nav li a:hover,
.nav-sticky.navbar-custom .navbar-nav li a:focus,
.nav-sticky.navbar-custom .navbar-nav li a:active {
    color: #0076a3 !important;
}

.bg-gradiant {
    background: linear-gradient(80deg, #46a5bd 0%, #116a8d 100%);
    border: none !important;
    color: #fff !important;

}